import * as React from "react";
import Layout from "../../layouts/en";
import PageHead from "../../components/shared/PageHead";
import WorkFlowList from "../../components/pages/WokrFlowList";
import { StaticImage } from "gatsby-plugin-image";
import content from "../../data/content/wokrflow/workflow-en";
import * as styles from "../../styles/pages/workflow/index.module.css";
import Intro from "../../components/shared/Intro";

const WorkFlow = (props) => {
  const { sIntroSection, sIntro, sImgSec } = styles;
  const ImgSec = () => {
    return (
      <section className={sImgSec}>
        <StaticImage
          src="../../assets/images/workflow/office.jpeg"
          alt="Office"
          layout="fullWidth"
        />
      </section>
    );
  };
  return (
    <Layout location={props.location} lang="en">
      <PageHead pageHeadData={content.seo} />
      <article className="workflow" dir="ltr">
        <Intro content={content.intro} styles={{ sIntroSection, sIntro }} />
        <ImgSec />
        <WorkFlowList content={content} />
      </article>
    </Layout>
  );
};

export default WorkFlow;
