module.exports = {
    seo: {
        title : "Jenko - Project Steps",
        description:  ""
    },
    intro : {
        title :  "Project Steps",
    },
    workFlowItems: [
        {   
            name: 'brain',
            title : 'Brainstorming',
            desc : [
                "Initial meeting to discuss your idea. ",
                "his meeting develops the idea from an engineering, marketing and financial\u00A0perspective."
            ]
        },
        {   
            name: 'spec',
            title : 'Specification and\u00A0Feasibility',
            desc : [
                "At this initial stage of the project, the customer provides requirements (product features) while the development company prepares engineering and design requirements and design (engineering feasibility), market survey on similar products, product specification based on results, product sketching, marketing concepts (marketing feasibility), and preliminary unit cost assessment for mass production\u00A0(financial\u00A0feasibility)."
            ]
        },
        {   
            name: 'prot',
            title : 'Prototype Construction',
            desc : [
                "Transfer from planning to production. This is a challenging phase which requires lots of work, resources, and raw\u00A0materials.",
                "Therefore, this is a delicate step in transitioning the idea into an actual product. ",
                "Results of specification are one of the most important parameters in determining whether or not to begin prototype\u00A0development."
               ]
        },
        {   
            name: 'patent',
            title : 'Patent Search &\u00A0Registration',
            desc : [
                 "To protect your idea and business venture, we recommend searching and registering your patent (if possible; it is not always\u00A0possible)",
                  'To accomplish this, we work in close coordination with the law firm of “Luzzatto and Luzzatto“ , providing you with the utmost assurance the search and registration process has been done\u00A0properly.'
                 ]
        },
        {   
            name: 'market',
            title : 'Marketing',
            desc : [
                "Building a marketing strategy, creating one-pager, videos, and a business plan. This stage is vital preparation for onboarding\u00A0investors.",
                "The more you put into this stage and come prepared with information to offer investors, the greater the chances of success your fundraising\u00A0will\u00A0have."
                 ]
        },
        {   
            name: 'invest',
            title : 'Investor Recruiting',
            desc : [
                 "We assist you by providing connections to angel investors (private investors), crowdfunding, venture capital funds,\u00A0and\u00A0grants."
             ]
        },
        {   
            name: 'prod',
            title : 'Preparation for Mass\u00A0Production',
            desc : [
                 'Obtaining price quotes from factories, establishing of production lines, quality inspection of product to the final, finished product stage; mass production, packing, assembly, shipment to Israel\u00A0and\u00A0abroad.',
                ]
        },
    ],
     dir: "rtl"
};